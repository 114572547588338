<template>
  <div>
    <app-cells position="start">
      <app-button
        v-for="(file, index) of files"
        :key="index"
        size="link"
        :class="{'btn--loading': file.loader}"
        @click="onGetFile(file)"
      >
        <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
        <span>{{ file.name }}</span>
      </app-button>
    </app-cells>
    <app-grid>
      <template #item-1>
        <app-form-group label="Направления" :indent="false">
          <v-select
            v-model="direction"
            :reduce="item => item.id"
            :options="directions_options"
            @input="getIntervals"
            :filterable="false"
            :clearable="false"
            label="name"
            placeholder="Выберите направление"
            class="select"
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.name }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.name }}</div>
            </template>
            <template #no-options>Ничего не найдено</template>
          </v-select>
        </app-form-group>
        <app-cells position="start">
          <app-input v-model="numbers_out_search" placeholder="Поиск" />
        </app-cells>
        <app-cells position="between">
          <span>Номеров: <b>{{ numbersOutFiltered.length }}</b></span>
          <span>Продолжительность: <b>{{ out_time }}</b></span>
        </app-cells>
        <draggable
          :list="numbersOutFiltered"
          @start="onDragOutStart"
          @end="onDragOutEnd"
          v-bind="dragOptions"
          class="sortable"
        >
          <transition-group type="transition" :name="!drag_out ? 'flip-list' : null" tag="div">
            <div v-for="(element, index) in numbersOutFiltered" :key="index" class="sortable__item">
              {{ element.text_string }}
            </div>
          </transition-group>
        </draggable>
      </template>
      <template #item-2>
        <app-form-group label="Промежуток" :indent="false">
          <v-select
            v-model="interval"
            :reduce="item => item.id"
            :options="intervals_options"
            @input="onGetAllProgram"
            :filterable="true"
            :clearable="false"
            label="date"
            placeholder="Выберите промежуток"
            class="select"
          >
            <template #open-indicator>
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template #option="option">
              <div class="select__item d-center">{{ option.date }} {{ option.time_start }} - {{ option.time_end }}</div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">{{ option.date }} {{ option.time_start }} - {{ option.time_end }}</div>
            </template>
            <template #no-options>Ничего не найдено</template>
          </v-select>
        </app-form-group>
        <div class="custom-program-place">
          <app-input v-model="numbers_in_search" placeholder="Поиск" />
          <app-button
            @click="onProgramSend"
            type="button"
            size="small"
            :disabled="!direction || !interval"
            ref="submit"
          >
            Сохранить
          </app-button>
        </div>
        <app-cells position="between">
          <span>Номеров: <b>{{ numbersInFiltered.length }}</b></span>
          <span>Продолжительность: <b>{{ in_time }}</b></span>
        </app-cells>
        <draggable
          :list="numbersInFiltered"
          @start="onDragInStart"
          @end="onDragInEnd"
          v-bind="dragOptions"
          class="sortable"
        >
          <transition-group type="transition" :name="!drag_in ? 'flip-list' : null" tag="div">
            <div v-for="(element, index) in numbersInFiltered" :key="index" class="sortable__item">
              {{ index + 1 }}. {{ element.text_string }}
            </div>
          </transition-group>
        </draggable>
      </template>
    </app-grid>
  </div>
</template>

<script>
import {
  getDirections,
  getProgramInterval,
  getAllProgram,
  patchAllProgram,
  getProgramExcel,
  getProgramZipPdf,
  getProgramZipWord,
  getProgramNumbersZip
} from '@/http'
import draggable from 'vuedraggable'
import { format, parse } from 'date-fns'
import ru from 'date-fns/locale/ru'

export default {
  name: 'Program',
  components: { draggable },
  data() {
    return {
      directions_options: [],
      direction: null,
      intervals_options: [],
      interval: null,
      all_program: {
        numbers_in: [],
        numbers_out: []
      },
      numbers_in_search: '',
      numbers_out_search: '',
      drag_in: false,
      drag_out: false,
      in_time: '0',
      out_time: '0',
      files: [
        {
          name: 'Скачать программу (Excel)',
          name_file: 'Программа',
          loader: false,
          action: getProgramExcel,
          format: 'xlsx',
        },
        {
          name: 'Скачать программу (Word)',
          name_file: 'Программа (Word)',
          loader: false,
          action: getProgramZipWord,
          format: 'zip',
        },
        {
          name: 'Скачать программу (PDF)',
          name_file: 'Программа (PDF)',
          loader: false,
          action: getProgramZipPdf,
          format: 'zip',
        },
        {
          name: 'Скачать номера программы (PDF)',
          name_file: 'Номера программы (PDF)',
          loader: false,
          action: getProgramNumbersZip,
          format: 'zip',
        },
      ]
    }
  },
  computed: {
    numbersInFiltered() {
      return this.all_program.numbers_in.filter(item => item.text_string.toLowerCase().indexOf(this.numbers_in_search) !== -1)
    },
    numbersOutFiltered() {
      return this.all_program.numbers_out.filter(item => item.text_string.toLowerCase().indexOf(this.numbers_out_search) !== -1)
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'program',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  created() {
    getDirections().then(res => { this.directions_options = res.data })
  },
  methods: {
    onGetFile(file) {
      file.loader = true
      file.action()
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${file.name_file} ${format(new Date(), 'dd.MM.yyyy HH_mm')}.${file.format}`)
          document.body.appendChild(link)
          link.click()
        })
        .finally(() => {
          file.loader = false
        })
    },
    onProgramSend() {
      if (this.all_program.id) {
        this.$refs.submit.preload = true
        patchAllProgram(this.all_program.id,
          {
            numbers: this.numbersInFiltered.map(item => item.number),
            time: this.all_program.time
          })
          .finally(() => { this.$refs.submit.preload = false })
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Успех',
              text: 'Программа сохранена'
            })
          })
      } else {
        this.$notify({
          type: 'warn',
          title: 'Внимание',
          text: 'Настройте программу'
        })
      }
    },
    getIntervals() {
      this.intervals_options = []
      this.interval = null
      this.all_program = {
        numbers_in: [],
        numbers_out: []
      }
      getProgramInterval({ direction: this.direction })
        .then(res => {
          this.intervals_options = res.data.map(item => ({
            ...item,
            date: format(parse(item.date, 'yyyy-MM-dd', new Date()), 'dd MMM',  { locale: ru }),
            time_start: item.time_start.substring(0, item.time_start.length - 3),
            time_end: item.time_end.substring(0, item.time_end.length - 3),
          }))
        })
    },
    onGetAllProgram() {
      if (this.interval && this.direction) {
        getAllProgram(this.interval, this.direction)
          .then(res => {
            if (res.data.id) {
              this.all_program = res.data
              this.calcDurationIn()
              this.calcDurationOut()
            } else {
              this.all_program = {
                numbers_in: [],
                numbers_out: []
              }
              this.$notify({
                type: 'warn',
                title: 'Внимание',
                text: 'Программа еще не создана'
              })
            }
          })
      }
    },
    calcDurationIn() {
      let hours_in = 0

      this.numbersInFiltered.forEach(item => { hours_in += item.duration })
      this.in_time = `${Math.floor(hours_in / 60 / 60)} ч. ${Math.floor(hours_in / 60) - (Math.floor(hours_in / 60 / 60) * 60)} мин. ${hours_in % 60} сек.`
    },
    calcDurationOut() {
      let hours_out = 0

      this.numbersOutFiltered.forEach(item => { hours_out += item.duration })
      this.out_time = `${Math.floor(hours_out / 60 / 60)} ч. ${Math.floor(hours_out / 60) - (Math.floor(hours_out / 60 / 60) * 60)} мин. ${hours_out % 60} сек.`
    },
    onDragInEnd() {
      this.drag_in = false
      this.calcDurationIn()
      this.calcDurationOut()
    },
    onDragOutEnd() {
      this.drag_out = false
      this.calcDurationIn()
      this.calcDurationOut()
    },
    onDragInStart() {
      this.drag_in = true
      this.calcDurationIn()
      this.calcDurationOut()
    },
    onDragOutStart() {
      this.drag_out = true
      this.calcDurationIn()
      this.calcDurationOut()
    }
  }
}
</script>

<style lang="sass" scoped>
.sortable
  > div
    min-height: 3000px

.sortable__item
  font-size: 13px
  padding: 6px 0
  border-bottom: 1px solid #9b9ca2
  cursor: move

  &:last-child
    border-bottom: none

.custom-program-place
  display: flex
  align-items: center
  justify-content: space-between
  gap: 10px 20px
  margin: 20px 0

  input
    flex-grow: 1

  button
    flex-shrink: 0

.flip-list-move
  transition: transform 0.5s

.no-move
  transition: transform 0s

.ghost
  opacity: 0.5
</style>
